import * as React from 'react';
import { addDays, addMonths, min, parseISO } from 'date-fns';

import { createNormalizers, useBaseData, useSearchParameters } from '../../lib';
import { cabinTypeEnum } from '../../api';
import { ensureOptionArray, Option } from '../../types/Option';

import Search from './Search';
import { SearchState } from './SearchState';

const INITIAL_ADULTS = 2;
const INITIAL_CHILDREN = 0;
const INITIAL_MONTH_RANGE = 3;
const NO_DURATION: Option = {
  code: '',
  name: 'Nicht festgelegt',
  separate: true,
};

function useResetState(searchState: null | any) {
  const baseData = useBaseData();
  return React.useMemo(() => {
    const normalize = createNormalizers(baseData);

    const {
      cruise,
      flight,
      geoLocations,
      periods,
    } = baseData || {};

    const {
      duration,
      passengers,
      dateRange = Infinity,
      ships,
      cabinTypeInfos: cabinTypes,
    } = cruise || {};

    const { airports } = flight || {};
    const { regionInfos: regions } = geoLocations || {};

    const firstCheckInDate = searchState
      ? parseISO(searchState.period.start.date)
      : parseISO(periods?.firstCheckInDate ?? '');
    const baseDataLastCheckoutDate = parseISO(periods?.lastCheckOutDate ?? '');
    const lastCheckOutDate = searchState
      ? parseISO(searchState.period.end.date)
      : min([
        addMonths(firstCheckInDate, INITIAL_MONTH_RANGE),
        baseDataLastCheckoutDate,
      ]);

    return {
      initialState: {
        arrivalLikeDeparture: false,
        adults: searchState
          ? searchState.passenger.adults
          : normalize.adults(INITIAL_ADULTS),
        children: searchState
          ? searchState.passenger.children
          : normalize.children(INITIAL_CHILDREN),
        cruiseDuration:
          (searchState &&
            ensureOptionArray(duration).find(
              ({ code }) => code === searchState.period.duration,
            )) ||
          NO_DURATION,
        arrivalAirports:
          (searchState &&
            ensureOptionArray(airports).filter(({ code }) =>
              searchState.flight.inbound.arrivalAirports.includes(code),
            )) ||
          [],
        ships:
          (searchState &&
            ensureOptionArray(ships).filter(({ code }) =>
              searchState.cruise.shipCodes.includes(code),
            )) ||
          [],
        regions:
          (searchState &&
            ensureOptionArray(regions).filter(({ code }) =>
              searchState.geoLocation.regions.includes(code),
            )) ||
          [],
        cabinTypes:
          (searchState &&
            ensureOptionArray(cabinTypes).filter(({ code }) =>
              searchState.cruise.cabinTypes.includes(code as cabinTypeEnum),
            )) ||
          [],
        departureAirports:
          (searchState &&
            ensureOptionArray(airports).filter(({ code }) =>
              searchState.flight.outbound.departureAirports.includes(code),
            )) ||
          [],
        firstCheckInDate,
        lastCheckOutDate:
          dateRange === Infinity
            ? lastCheckOutDate
            : min([addDays(firstCheckInDate, dateRange), lastCheckOutDate]),
      } as SearchState,
      options: {
        cruise: {
          duration: [NO_DURATION].concat(duration ?? []),
          passengers: passengers ?? {
            maxAdults: 0,
            maxChildren: 0,
            minAdults: 0,
            minChildren: 0,
          } as {
            maxAdults: number;
            minAdults: number;
            maxChildren: number;
            minChildren: number;
          },
          dateRange,
          ships: ensureOptionArray(ships).map((ship) => ({ name: ship.name ?? '', code: ship.code ?? '' })),
          cabinTypes: ensureOptionArray(cabinTypes),
        },
        flight: {
          airports: ensureOptionArray(airports),
        },
        periods: {
          firstCheckInDate: parseISO(periods?.firstCheckInDate ?? ''),
          lastCheckOutDate: parseISO(periods?.lastCheckOutDate ?? ''),
        },
        geoLocations: {
          regions: ensureOptionArray(regions),
        },
      },
    };
  }, [baseData, searchState]);
}

export function useSearchData() {
  const searchState = useSearchParameters();
  return useResetState(searchState);
}

const SearchDataProvider: React.FC = ({ children }) => {
  const searchData = useSearchData();
  const { initialState: resetState } = useResetState(null);

  return <Search resetState={resetState} {...searchData} >{children}</Search>;
};

export default SearchDataProvider;
