import * as React from 'react';
import { isAfter, isBefore, parseISO } from 'date-fns/esm';
import memoizeOne from 'memoize-one';

import { isOption, Option } from '../types';

import { BaseDataResponse, cabinTypeEnum, getBaseData } from '../api';
import { captureMessage, inRange, isAbort, Severity, withSentryData } from '../lib';
import useAsyncEffect from './useAsyncEffect';
import { withAbort } from './fetch';
import { formatISO } from './formatDate';
import { getAirports } from './getAirports';

const IGNORE_PATHS = ['processInfo.processMessages', 'cruise.ports'];

export type BaseData = BaseDataResponse;

function removePartialOptions(data: Object[], key: string): Object[] {
  return data.filter((data: any, i: number, self): data is Option => {
    const result = isOption(data);

    if (data.name === '----- ' && i !== 0) {
      (self[i - 1] as Option).separate = true;
    } else if (!result) {
      captureMessage(
        withSentryData({ severity: Severity.Warning })(
          `${key}[${i}] is not a valid options`,
        ),
      );
    }

    return result;
  });
}

type DataObject = {
  [key: string]:
    | DataObject
    | string
    | string[]
    | Object[]
    | boolean
    | number
    | undefined;
};

function getVal(value: DataObject[string], path: string[]) {
  if (IGNORE_PATHS.includes(path.join('.'))) {
    return value;
  }

  if (Array.isArray(value)) {
    if (value.length >= 1 && typeof value[0] === 'object') {
      return removePartialOptions(value, path.join('.'));
    }
  } else if (typeof value === 'object') {
    return removePartialOptionsRecursive(value, path);
  }

  return value;
}

function removePartialOptionsRecursive<D extends DataObject>(
  data: D,
  parentKeys: string[] = [],
): D {
  return Object.entries(data).reduce(
    (memo, [key, value]: [keyof D, DataObject[string]]) => {
      const path = parentKeys.concat(`${key}`);

      memo[key] = getVal(value, path) as any;

      return memo;
    },
    {} as D,
  );
}

function movePastDatesToPresent({
                                  periods,
                                  ...rest
                                }: BaseDataResponse): BaseDataResponse {
  const now = new Date();
  now.setDate(now.getDate() + 3);
  return {
    ...rest,
    periods: {
      firstCheckInDate: isBefore(parseISO(periods?.firstCheckInDate ?? ''), now)
        ? formatISO(now)
        : periods?.firstCheckInDate ?? '',
      lastCheckOutDate: isBefore(parseISO(periods?.lastCheckOutDate ?? ''), now)
        ? formatISO(now)
        : periods?.lastCheckOutDate ?? '',
    },
  };
}

function sortSpecialShips({
                            ...rest
                          }: BaseDataResponse): BaseDataResponse {
  return {
    ...rest,
    cruise: {
      ...rest.cruise,
      shipCodes: rest.cruise?.shipCodes ?? [],
      ships: (rest.cruise?.ships ?? []).map((value, index) => ({ value, index })) // Attach original indices
        .sort((a, b) => {
          // Mein Schiff Flow soll nach Relax auftauchen
          if ((a.value.code ?? '') === 'MEINSR' && (b.value.code ?? '') === 'MEINSF') {
            return -1;
          }


          return a.value.code?.localeCompare(b.value.code ?? '') ?? 0;
        })
        .map(item => {
          return item.value;
        }),
    },
  };
}

export const createNormalizers = memoizeOne(
  ({
     cruise,
     flight,
     geoLocations,
     periods,
   }: BaseData) => {
    const durationCodes = (cruise?.duration ?? []).map(({ code }) => code);
    const regionCodes = (geoLocations?.regionInfos ?? []).map(({ code }) => code);
    const airportCodes = (flight?.airports ?? []).map(({ code }) => code);
    const shipCodes = (cruise?.ships ?? []).map(({ code }) => code);
    const cabinCodes = (cruise?.cabinTypeInfos ?? []).map(({ code }) => code);

    return {
      adults: (amount: number) => inRange(amount, [cruise?.passengers?.minAdults ?? 0, cruise?.passengers?.maxAdults ?? 0]),
      children: (amount: number) => inRange(amount, [cruise?.passengers?.minChildren ?? 0, cruise?.passengers?.maxChildren ?? 0]),
      startDate: (date: string) =>
        isBefore(parseISO(periods?.firstCheckInDate ?? ''), parseISO(date))
          ? date
          : periods?.firstCheckInDate ?? '',
      endDate: (date: string) =>
        isAfter(parseISO(periods?.lastCheckOutDate ?? ''), parseISO(date))
          ? date
          : periods?.lastCheckOutDate ?? '',
      duration: (duration: string) =>
        duration.length && durationCodes.includes(duration)
          ? duration
          : undefined,
      regions: (regions: string[]) =>
        regions.filter((code) => regionCodes.includes(code)),
      airports: (airports: string[]) =>
        airports.filter((code) => airportCodes.includes(code)),
      ships: (ships: string[]) =>
        ships.filter((code) => shipCodes.includes(code)),
      cabins: (cabins: string[]): cabinTypeEnum[] =>
        cabins.filter((code): code is cabinTypeEnum =>
          cabinCodes.includes(code),
        ),
    };
  },
);

function normalizeBaseData(data: BaseDataResponse): BaseDataResponse {
  return sortSpecialShips(movePastDatesToPresent(removePartialOptionsRecursive(data)));
}

const BaseDataContext = React.createContext<BaseData | null | Error>(null);

export const BaseDataProvider: React.FC = ({ children }) => {
  const [baseData, setBaseData] = React.useState<BaseData | null | Error>(null);
  useAsyncEffect(async (onUnmount) => {
    try {
      const data = await getBaseData(withAbort(onUnmount));
      const airports = await getAirports();


      // with CS-S V2 Migration the following base data had to be hardcoded due to them not changing anyway
      if (data.cruise) {
        data.cruise.tariffs = [
          { code: 'PRO', name: 'PRO-Tarif' }, {
            code: 'PLUS',
            name: 'PLUS-Tarif',
          },
          { code: 'PUR', name: 'PUR-Tarif' }];
        data.cruise.cabinTypeInfos = [
          {
            'code': 'Interior',
            'name': 'Innenkabine',
          },
          {
            'code': 'Balcony',
            'name': 'Balkonkabine',
          },
          {
            'code': 'Veranda',
            'name': 'Verandakabine',
          },
          {
            'code': 'Family',
            'name': 'Familienkabine',
          },
          {
            'code': 'Outside',
            'name': 'Außenkabine',
          },
          {
            'code': 'JuniorSuite',
            'name': 'Junior Suite',
          },
          {
            'code': 'Suite',
            'name': 'Suite',
          },
          {
            'code': 'Single',
            'name': 'Einzelkabine',
          },
          {
            'code': 'SingleInterior',
            'name': 'Einzelkabine innen',
          },
          {
            'code': 'SingleOutside',
            'name': 'Einzelkabine außen',
          },
          {
            'code': 'SingleBalcony',
            'name': 'Einzelkabine Balkon',
          },
        ];

        data.cruise.cabinTypes = [
          'Interior',
          'Balcony',
          'Veranda',
          'Family',
          'Outside',
          'JuniorSuite',
          'Suite',
          'Single',
          'SingleInterior',
          'SingleOutside',
          'SingleBalcony',
        ] as cabinTypeEnum[];
      }


      // cs-s v2 update stranger thing - Remove properties from cruise
      if (data.cruise?.properties) {
        delete data.cruise?.properties;
      }

      setBaseData(normalizeBaseData({
        ...data,
        flight: {
          airports,
          arrivalAirports: data.flight?.arrivalAirports ?? [],
          departureAirports: data.flight?.departureAirports ?? [],
        },
      }));
    } catch (err) {
      if (isAbort(err)) {
        return;
      }

      if (err instanceof Error) {
        setBaseData(err);
      }
      throw err;
    }
  }, []);

  return (
    <BaseDataContext.Provider value={baseData}>
      {children}
    </BaseDataContext.Provider>
  );
};

export default function useBaseData(): BaseData;
export default function useBaseData(force: true): BaseData | null | Error;
export default function useBaseData(force?: true): BaseData | null | Error {
  const baseData = React.useContext(BaseDataContext);

  if (force === true) {
    return baseData;
  }

  if (baseData instanceof Error || !baseData) {
    throw new Error('Could not find BaseData');
  }

  return baseData;
}
