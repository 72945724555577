/**
 * DO NOT MODIFY - This file has been generated using oazapfts.
 */
type Encoders = Array<(s: string) => string>;
// Encode param names and values as URIComponent
const encodeReserved = [encodeURIComponent, encodeURIComponent];
// Allow reserved chars in param values
const allowReserved = [encodeURIComponent, encodeURI];
type RequestOpts = {
    headers?: Record<string, string | undefined>;
    method?: string;
};
type FetchRequestOpts = RequestOpts & {
    body?: string | FormData;
};
type JsonRequestOpts = RequestOpts & {
    body: object;
};
type MultipartRequestOpts = RequestOpts & {
    body: Record<string, string | Blob | undefined | any>;
};
type ServerI = {
    url: string;
};
export type ApiOptions = {
    /**
     * @deprecated "baseUrl" has been renamed to "server"
     */
    baseUrl?: string;
    server?: ServerI | string;
    fetch?: typeof fetch;
} & RequestInit;
function getUrl(server: string | ServerI): string {
    if (typeof server === "string") {
        return server;
    }
    return server.url;
}
export class Api {
    private _baseUrl: string;
    private _fetchImpl?: typeof fetch;
    private _fetchOpts: RequestInit;
    constructor({ baseUrl = "https://api.tuicruises.com/cruisesearch/{version}/", server, fetch: fetchImpl, ...fetchOpts }: ApiOptions = {}) {
        this._fetchImpl = fetchImpl;
        this._baseUrl = server ? getUrl(server) : baseUrl;
        this._fetchOpts = fetchOpts;
    }
    private async _fetch(url: string, req: FetchRequestOpts = {}) {
        const headers = stripUndefined(req.headers);
        const res = await (this._fetchImpl || fetch)(this._baseUrl + url, {
            ...this._fetchOpts,
            ...req,
            headers
        });
        if (!res.ok) {
            throw new HttpError(res.status, res.statusText);
        }
        return res.text();
    }
    private async _fetchJson(url: string, req: FetchRequestOpts = {}) {
        const res = await this._fetch(url, {
            ...req,
            headers: {
                ...req.headers,
                Accept: "application/json"
            }
        });
        return JSON.parse(res);
    }
    private _json({ body, headers, ...req }: JsonRequestOpts) {
        return {
            ...req,
            body: JSON.stringify(body),
            headers: {
                ...headers,
                "Content-Type": "application/json"
            }
        };
    }
    private _form({ body, headers, ...req }: JsonRequestOpts) {
        return {
            ...req,
            body: QS.form(body),
            headers: {
                ...headers,
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };
    }
    private _multipart({ body, ...req }: MultipartRequestOpts) {
        const data = new FormData();
        Object.entries(body).forEach(([name, value]) => {
            data.append(name, value);
        });
        return {
            ...req,
            body: data
        };
    }
    /**
     * retrieves base data
     */
    async getBaseData() {
        return await this._fetchJson("/baseData") as baseDataResponse;
    }
    /**
     * searches cruise offers
     */
    async postCruiseList(cruiseListRequest: cruiseListRequest) {
        return await this._fetchJson("/cruiseList", this._json({
            method: "POST",
            body: cruiseListRequest
        })) as cruiseListResponse;
    }
}
/**
 * Deeply remove all properties with undefined values.
 */
function stripUndefined<T>(obj: T) {
    return obj && JSON.parse(JSON.stringify(obj));
}
/**
 * Creates a tag-function to encode template strings with the given encoders.
 */
function encode(encoders: Encoders, delimiter = ",") {
    const q = (v: any, i: number) => {
        const encoder = encoders[i % encoders.length];
        if (typeof v === "object") {
            if (Array.isArray(v)) {
                return v.map(encoder).join(delimiter);
            }
            const flat = Object.entries(v).reduce((flat, entry) => [...flat, ...entry], [] as any);
            return flat.map(encoder).join(delimiter);
        }
        return encoder(String(v));
    };
    return (strings: TemplateStringsArray, ...values: any[]) => {
        return strings.reduce((prev, s, i) => {
            return `${prev}${s}${q(values[i] || "", i)}`;
        }, "");
    };
}
/**
 * Separate array values by the given delimiter.
 */
function delimited(delimiter = ",") {
    return (params: Record<string, any>, encoders = encodeReserved) => Object.entries(params)
        .filter(([, value]) => value !== undefined)
        .map(([name, value]) => encode(encoders, delimiter) `${name}=${value}`)
        .join("&");
}
/**
 * Functions to serialize query parameters in different styles.
 */
export const QS = {
    encode,
    encodeReserved,
    allowReserved,
    /**
     * Join params using an ampersand and prepends a questionmark if not empty.
     */
    query(...params: string[]) {
        const s = params.join("&");
        return s && `?${s}`;
    },
    /**
     * Serializes nested objects according to the `deepObject` style specified in
     * https://github.com/OAI/OpenAPI-Specification/blob/master/versions/3.0.0.md#style-values
     */
    deep(params: Record<string, any>, [k, v] = encodeReserved): string {
        const qk = encode([s => s, k]);
        const qv = encode([s => s, v]);
        // don't add index to arrays
        // https://github.com/expressjs/body-parser/issues/289
        const visit = (obj: any, prefix = ""): string => Object.entries(obj)
            .filter(([, v]) => v !== undefined)
            .map(([prop, v]) => {
            const index = Array.isArray(obj) ? "" : prop;
            const key = prefix
                ? qk `${prefix}[${index}]` : prop;
            if (typeof v === "object") {
                return visit(v, key);
            }
            return qv `${key}=${v}`;
        })
            .join("&");
        return visit(params);
    },
    /**
     * Property values of type array or object generate separate parameters
     * for each value of the array, or key-value-pair of the map.
     * For other types of properties this property has no effect.
     * See https://github.com/OAI/OpenAPI-Specification/blob/master/versions/3.0.0.md#encoding-object
     */
    explode(params: Record<string, any>, encoders = encodeReserved): string {
        const q = encode(encoders);
        return Object.entries(params)
            .filter(([, value]) => value !== undefined)
            .map(([name, value]) => {
            if (Array.isArray(value)) {
                return value.map(v => q `${name}=${v}`).join("&");
            }
            if (typeof value === "object") {
                return QS.explode(value, encoders);
            }
            return q `${name}=${value}`;
        })
            .join("&");
    },
    form: delimited(),
    pipe: delimited("|"),
    space: delimited("%20")
};
export class HttpError extends Error {
    status: number;
    constructor(status: number, message: string) {
        super(message);
        this.status = status;
    }
}
type PromisedApiResult<M> = M extends (...args: any) => Promise<infer T> ? T : never;
export type ApiResult<N extends keyof Api> = PromisedApiResult<Api[N]>;
export type messageTypeEnum = "Error" | "ConfigurationError" | "Information";
export type processMessages_inner = {
    messageType?: messageTypeEnum;
    processIdentifier?: string;
    messageCode?: string;
    message?: string;
};
export type processMessages = processMessages_inner[];
export type processInfo = {
    success: boolean;
    time?: number;
    requestId?: string;
    processMessages?: processMessages;
};
export type baseDataCruiseShips_inner = {
    code?: string;
    name?: string;
};
export type baseDataCruiseShips = baseDataCruiseShips_inner[];
export type baseDataCruisePorts_inner = {
    code?: string;
    name?: string;
    country?: string;
    isDeparturePort?: boolean;
    isArrivalPort?: boolean;
};
export type baseDataCruisePorts = baseDataCruisePorts_inner[];
export type cabinTypeEnum = ("Balcony" | "JuniorSuite" | "Interior" | "Veranda" | "Suite" | "Outside" | "Family" | "DeluxeSuite" | "PanoramaSuite" | "PanoramaBalcony" | "FamilyBalcony" | "FamilyVeranda" | "SkySuite" | "HorizonSuite" | "ViewSuite" | "SpaBalcony" | "OverseaSuite" | "JuniorSuiteVeranda" | "VerandaSuite" | "ThemeSuite" | "CombiBalcony") & null;
export type baseDataCruiseCabinTypeInfos_inner = {
    code: string;
    name: string;
};
export type baseDataCruiseCabinTypeInfos = baseDataCruiseCabinTypeInfos_inner[];
export type baseDataCruiseTariffTypes_inner = {
    code?: string;
    name?: string;
};
export type baseDataCruiseTariffTypes = baseDataCruiseTariffTypes_inner[];
export type cruiseProperties_inner = {
    code?: string;
    name?: string;
};
export type cruiseProperties = cruiseProperties_inner[];
export type baseDataCruise_passengers = {
    maxAdults: number;
    minAdults: number;
    maxChildren: number;
    minChildren: number;
};
export type baseDataCruiseDuration_inner = {
    code: string;
    name: string;
};
export type baseDataCruiseDuration = baseDataCruiseDuration_inner[];
export type baseDataCruise = {
    shipCodes: string[];
    ships?: baseDataCruiseShips;
    ports?: baseDataCruisePorts;
    cabinTypes?: cabinTypeEnum[];
    cabinTypeInfos?: baseDataCruiseCabinTypeInfos;
    tariffTypes?: string[];
    tariffs?: baseDataCruiseTariffTypes;
    dateRange?: number;
    properties?: cruiseProperties;
    passengers?: baseDataCruise_passengers;
    duration?: baseDataCruiseDuration;
};
export type baseDataFlightAirports_inner = {
    code?: string;
    name?: string;
    isDepartureAirport?: boolean;
    isArrivalAirport?: boolean;
};
export type baseDataFlightAirports = baseDataFlightAirports_inner[];
export type baseDataFlight = {
    departureAirports: string[];
    arrivalAirports: string[];
    airports?: baseDataFlightAirports;
};
export type baseDataGeoLocationsRegionInfos_inner = {
    code: string;
    name: string;
};
export type baseDataGeoLocationsRegionInfos = baseDataGeoLocationsRegionInfos_inner[];
export type baseDataGeoLocationDestinations_inner = {
    code?: string;
    name?: string;
};
export type baseDataGeoLocationDestinations = baseDataGeoLocationDestinations_inner[];
export type baseDataGeoLocations = {
    regions?: string[];
    regionInfos?: baseDataGeoLocationsRegionInfos;
    destinations?: baseDataGeoLocationDestinations;
};
export type priceTypeEnum = ("None" | "Base" | "Board" | "EarlyBird" | "ExtraDay" | "StayPay" | "ChildReduction" | "DurationReduction" | "ChildPrice" | "OccupancyReduction" | "OccupancySupplement" | "BoardChildReduction" | "BoardSupplement" | "SelfDrivingSupplement" | "GeneralSupplement" | "GeneralDiscount" | "FuelSurcharge" | "AirPassengerDuty" | "AirportSurcharge" | "FlightSupplement" | "FlightTax" | "SecurityCharge" | "FirstMinute" | "LastMinute" | "UltraFirstMinute" | "UltraLastMinute" | "CustomPriceType1" | "CustomPriceType2" | "CustomPriceType3" | "CustomPriceType4" | "CustomPriceType5" | "CustomPriceType6" | "CustomPriceType7" | "CustomPriceType8" | "CustomPriceType9" | "CustomPriceType10" | "CustomPriceType11" | "CustomPriceType12" | "CustomPriceType13" | "CustomPriceType14" | "CustomPriceType15" | "CustomPriceType16" | "CustomPriceType17" | "CustomPriceType18" | "CustomPriceType19" | "CustomPriceType20" | "CustomPriceType21" | "CustomPriceType22" | "CustomPriceType23" | "CustomPriceType24" | "CustomPriceType25" | "CustomPriceType26" | "CustomPriceType27" | "CustomPriceType28" | "CustomPriceType29" | "CustomPriceType30") & null;
export type baseDataCampaigns_inner = {
    code?: string;
    name?: string;
    medium?: string;
};
export type baseDataCampaigns = baseDataCampaigns_inner[];
export type baseDataPeriods = {
    firstCheckInDate?: string;
    lastCheckOutDate?: string;
    durations?: number[];
};
export type baseDataResponse = {
    processInfo?: processInfo;
    lang?: string;
    currency?: string;
    cruise?: baseDataCruise;
    flight?: baseDataFlight;
    geoLocations?: baseDataGeoLocations;
    priceTypes?: priceTypeEnum[];
    campaigns?: baseDataCampaigns;
    periods?: baseDataPeriods;
};
export type detailLevelEnum = ("Default" | "CruiseItinerary" | "FlightSegments" | "PriceDetails") & null;
export type salesChannelEnum = ("Extern" | "Intern") & null;
export type pagingEntityEnum = "Journey" | "Route" | "RouteGroup";
export type pagingType = {
    resultsFrom?: number;
    resultsTotal?: number;
    resultsTotalEntity?: number;
    entity?: pagingEntityEnum;
};
export type sortCrieriaEnum = ("Price" | "DepartureDate") & null;
export type sortDirectionEnum = ("Asc" | "Desc") & null;
export type sortType = {
    criteria?: sortCrieriaEnum;
    direction?: sortDirectionEnum;
};
export type pasRequest = {
    adults: number;
    children: number;
};
export type periodDelimiter = {
    date: string;
};
export type periodRequest = {
    start: periodDelimiter;
    end?: periodDelimiter;
    duration?: string;
    durations?: number[];
};
export type geoLocationRequest = {
    regions?: string[];
    destinations?: string[];
};
export type flightRequest = {
    departureAirports?: string[];
    arrivalAirports?: string[];
};
export type flightsRequest = {
    outbound?: flightRequest;
    inbound?: flightRequest;
    allowOpenJaw?: boolean;
    allowOneway?: boolean;
};
export type cruiseRequest = {
    shipCodes?: string[];
    journeyIdentifier?: string[];
    cabinTypes?: cabinTypeEnum[];
    tariffTypes?: string[];
    routeGroupCodes?: string[];
    routeCodes?: string[];
    departurePortCodes?: string[];
    arrivalPortCodes?: string[];
    portCodes?: string[];
    properties?: string[];
    categoryTypes?: string[];
};
export type priceRequest = {
    priceTypes?: priceTypeEnum[];
    campaignCodes?: string[];
};
export type compositionTypeEnum = "None" | "JourneyIdentifier" | "JourneyIdentifierPriceCategory" | "JourneyIdentifierPriceCategoryCabinType" | "JourneyIdentifierWithoutProductType";
export type cruiseListRequest = {
    detailLevel?: detailLevelEnum[];
    salesChannel?: salesChannelEnum;
    showSoldOut?: boolean;
    paging?: pagingType;
    sort?: sortType;
    passenger: pasRequest;
    period: periodRequest;
    geoLocation?: geoLocationRequest;
    flight?: flightsRequest;
    cruise?: cruiseRequest;
    price?: priceRequest;
    composition?: compositionTypeEnum;
};
export type productTypeEnum = "Cruise" | "CruisePackage";
export type serviceReferences_inner = {
    serviceId?: number;
    serviceVariantId?: number;
};
export type serviceReferences = serviceReferences_inner[];
export type passengerTitleEnum = "H" | "D" | "K" | "B";
export type priceInformationPassengers_inner = {
    id?: number;
    amount?: number;
    title?: passengerTitleEnum;
};
export type priceInformationPassengers = priceInformationPassengers_inner[];
export type priceDetailsValidity = {
    bookingDateFrom?: string;
    bookingDateTo?: string;
};
export type priceDetails_inner = {
    priceType?: priceTypeEnum;
    codes?: string[];
    amount?: number;
    validity?: priceDetailsValidity;
};
export type priceDetails = priceDetails_inner[];
export type priceInformation = {
    amount: number;
    availability?: number;
    passengerPrices?: priceInformationPassengers;
    priceDetails?: priceDetails;
};
export type includedServiceTypeEnum = ("Other" | "RailAndFly" | "RailAndCruise" | "Transfer") & null;
export type includedServices_inner = {
    serviceType?: includedServiceTypeEnum;
    code?: string;
};
export type includedServices = includedServices_inner[];
export type campaigns_inner = {
    code?: string;
    name?: string;
    medium?: string;
    validity?: priceDetailsValidity;
};
export type campaigns = campaigns_inner[];
export type amenities_inner = {
    code?: string;
    name?: string;
};
export type amenities = amenities_inner[];
export type productResponse_inner = {
    id?: number;
    productType?: productTypeEnum;
    serviceReferences?: serviceReferences;
    priceInformation?: priceInformation;
    includedServices?: includedServices;
    campaigns?: campaigns;
    amenities?: amenities;
    routeGroupCode?: string;
    routeCode?: string;
};
export type productResponse = productResponse_inner[];
export type flightDirectionEnum = "Outbound" | "Inbound" | "Roundtrip";
export type flightService = {
    departureAirport: string;
    departureAirportName?: string;
    arrivalAirport: string;
    arrivalAirportName?: string;
    departureDate?: string;
    departureTime?: string;
    arrivalDate?: string;
    arrivalTime?: string;
    productCode?: string;
    bookingClassCode?: string;
    requestCode?: string;
    direction?: flightDirectionEnum;
};
export type cruiseServiceLocation_destinations = {
    code?: string;
    name?: string;
};
export type cruiseServiceLocation_route_ports = {
    code: string;
    name?: string;
    countryCode?: string;
    arrivalDateTime?: string;
    departureDateTime?: string;
};
export type cruiseServiceLocation_route = {
    code: string;
    code2?: string;
    name?: string;
    ports?: cruiseServiceLocation_route_ports[];
};
export type cruiseServiceLocation = {
    routeGroupCode?: string;
    originAirports?: string[];
    destinationAirports?: string[];
    region?: string;
    destinations?: cruiseServiceLocation_destinations[];
    route?: cruiseServiceLocation_route;
};
export type cruiseVariantCabin = {
    cabinCode: string;
    cabinName?: string;
    cabinType?: cabinTypeEnum;
    tariffType?: string;
    tariffName?: string;
    categoryTypes?: string[];
    packageOnly?: boolean;
};
export type boardTypeEnum = ("None" | "SelfCatering" | "Breakfast" | "HalfBoard" | "HalfBoardPlus" | "FullBoard" | "FullBoardPlus" | "AllInclusive" | "AllInclusivePlus") & null;
export type cruiseVariantBoard = {
    boardCode?: string;
    boardName?: string;
    boardType?: boardTypeEnum;
};
export type cruiseVariants_inner = {
    id: number;
    cabin: cruiseVariantCabin;
    board?: cruiseVariantBoard;
};
export type cruiseVariants = cruiseVariants_inner[];
export type cruiseService = {
    journeyIdentifier: string;
    startDate: string;
    endDate: string;
    packageReference?: string;
    shipCode: string;
    shipName?: string;
    requestCode?: string;
    properties?: cruiseProperties;
    location?: cruiseServiceLocation;
    cruiseVariants: cruiseVariants;
};
export type serviceResponse_inner = {
    id?: number;
    flightService?: flightService;
    cruiseService?: cruiseService;
};
export type serviceResponse = serviceResponse_inner[];
export type serviceTrips_inner = {
    journeyIdentifier: string;
    name: string;
    nights: string;
    ibeBlocked?: boolean;
};
export type serviceTrips = serviceTrips_inner[];
export type serviceInfoResponse = {
    trips: serviceTrips;
};
export type cruiseListResponse = {
    processInfo?: processInfo;
    lang?: string;
    currency?: string;
    paging?: pagingType;
    sort?: sortType;
    products: productResponse;
    services: serviceResponse;
    serviceInfos?: serviceInfoResponse;
};
export class SearchserviceUrlServer {
    url: string;
    constructor({ version = "v2" }: {
        version: "test-v2" | "v2";
    }) {
        this.url = `https://api.tuicruises.com/cruisesearch/${version}/`;
    }
}
