import * as React from 'react';
import styled from 'styled-components/macro';

import { colors } from '../../lib';

import { Cabin, CabinWithoutFlight, isCabinWithFlightOnly } from './Types';
import { Td } from '../Table';
import { Option } from '../../types';

import CabinPrice from './CabinPrice';
import byAmount from './sortByAmount';

type Props = {
  tariff: Option;
  cabins: Cabin[];
  filtered?: boolean;
};

const CabinTitle = styled.span`
  display: block;
  font-size: 16px;
  color: ${({ filtered }: { filtered?: boolean }) =>
    filtered || filtered === undefined ? colors.grayBlue : colors.disabledGray
  };
  height: 22px;
  line-height: 22px;
`;

const NO_CABIN = {
  price: { amount: Infinity },
} as CabinWithoutFlight;

function getComparableCabinPrice(cabin: Cabin): number {
  if (isCabinWithFlightOnly(cabin)) {
    return cabin.pricesWithFlight.sort(byAmount)[0].amount;
  }

  return cabin.price.amount;
}

const CheapestCabin: React.FC<Props> = React.memo(
  ({ tariff, cabins: allCabins, filtered }) => {
    const [cheapestCabin, earlyBird] = React.useMemo(() => {
      const cabins = allCabins.filter(({ tariffCode: t }) => t === tariff.code);

      return [
        cabins.reduce((memo, cabin) => {
          return getComparableCabinPrice(memo) > getComparableCabinPrice(cabin)
            ? cabin
            : memo;
        }, NO_CABIN),
        cabins.some(({ earlyBird }) => earlyBird),
      ];
    }, [allCabins, tariff]);

    if (cheapestCabin === NO_CABIN) {
      return <Td />;
    }


    return (
      <Td
        right
        triangle={earlyBird}
        data-testid={earlyBird ? 'earlybird' : undefined}
        filtered={filtered}
        singleExtra={!!cheapestCabin.singleExtra?.percent}
      >
        <CabinPrice bold={true} cabin={cheapestCabin} />
        <CabinTitle filtered={filtered}>
          {cheapestCabin.name}
        </CabinTitle>
        {cheapestCabin.singleExtra && cheapestCabin.singleExtra.percent && (<CabinTitle filtered={filtered}>
          {cheapestCabin.singleExtra.percent}% **
        </CabinTitle>)}
      </Td>
    );
  },
);

export default CheapestCabin;
