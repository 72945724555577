import pdfMake from 'pdfmake/build/pdfmake';
import pdfLayout from './pdfLayout';
import docDefinition from './docDefinition';
import {
  getCabinTypesAndCategories,
  getIbeTripInfo,
  ibeCabinTypesAndCategories,
  ibeContentTripInfo,
} from '../../../api/ibeApi';
import { getYtDiscount, TypeYTdiscount } from '../../../api/ytDiscount';
import getSvgFromUrl from './getSvgFromUrl';
import { UserData } from '../../../lib/auth/getUserData';
import { postCruiseList } from '../../../api';
import { format, withAbort } from '../../../lib';
import { convertCruises } from '../../CruiseList';
import { nightsTable } from './nightsTable';
import prepareContactText from './contactBox';
import { trackGA4Download } from '../../../tealium';

/**
 * TUICUNIT-1737
 * http://pdfmake.org/
 * */
export default async function createTarifInfoPdf(
  callback: () => void,
  tripCode: string,
  userData: UserData,
  detailRequest: any,
  baseData: any,
  cabinCode: string,
  selectedDepartureFlight: string,
) {
  //Use the Detail request and remove all detail informations an set only the trip and tarif
  detailRequest['cruise'] = {
    journeyIdentifier: [tripCode],
    tariffTypes: detailRequest['cruise'].tariffTypes,
  };
  detailRequest['passenger'] = {
    adults: 2,
    children: 0,
  };

  const cabinPriceList = convertCruises(
    await postCruiseList(
      detailRequest,
      withAbort(() => {
      }),
    ),
    baseData
  );

  const data: ibeContentTripInfo = await getIbeTripInfo(tripCode);
  const ibeCabin: ibeCabinTypesAndCategories[] = await getCabinTypesAndCategories(
    tripCode,
  );

  const ytData: TypeYTdiscount = await getYtDiscount(tripCode, cabinCode);

  //call the img and get it as plain text
  const img = await getSvgFromUrl(data.routeMapURL);

  //prepare contactBox and calculate background Box spaces
  const nightsInfo = nightsTable(data.itinerary);
  const contactBox = prepareContactText(userData);

  const fileName = getFileName(tripCode, data.dateFrom, data.dateTo);

  pdfMake
    .createPdf(
      docDefinition(
        data,
        img,
        contactBox,
        ibeCabin,
        cabinPriceList,
        nightsInfo,
        detailRequest['cruise'].tariffTypes,
        ytData,
        selectedDepartureFlight,
      ),
      pdfLayout,
    )
    .download(fileName, () => {
      trackGA4Download(fileName);
      callback();
    });
}

function getFileName(tripCode: string, startDate: string, endDate: string) {
  return `${tripCode}_${format(new Date(startDate), 'dd.MM.yyyy')}-${format(
    new Date(endDate),
    'dd.MM.yyyy',
  )}_Tarif-Info.pdf`;
}
