import * as React from 'react';
import { useHistory } from 'react-router';

import {
  ALL_CODE,
  captureException,
  isAbort,
  Severity,
  useAsyncEffect,
  useBaseData,
  useSearchParameters,
  useTariffs,
  withAbort,
} from '../../lib';
import ContentWrap, { ContentConfigWrap, OuterContentWrap } from '../ContentWrap';
import { CruiseListRequest, detailLevelEnum, postCruiseList } from '../../api';

import { isFilteredOption, Option } from '../../types';
import { Cruise } from './Types';
import CruiseList from './CruiseList';
import convertCruises from './convertCruises';
import ErrorWithReset from '../ErrorWithReset';
import SelectInputWrap from '../SelectInput';
import { PriceSwitch } from '../index';
import { trackGA4FormInteraction, trackGA4SearchEvent } from '../../tealium';

const SOME_ERROR = Symbol('SOME_ERROR');
const RESULTS_TOTAL = 999999;

const CruiseListProvider: React.FC = () => {
  const baseData = useBaseData();
  const [cruises, setCruises] = React.useState<
    Cruise[] | null | typeof SOME_ERROR
  >(null);
  const [filteredCruises, setFilteredCruises] = React.useState<Cruise[] | null>(null);
  const searchParams = useSearchParameters();
  const history = useHistory();
  const tariffs = useTariffs();
  const [disableTariffFilter, setDisableTariffFilter] = React.useState<boolean>(false);
  const [filteredTariffs, setFilteredTariffs] = React.useState<Option[]>([]);
  const request = React.useMemo<CruiseListRequest | Error>(() => {
    if (!searchParams) {
      return new Error('Could not find Search Params');
    }
    return {
      ...searchParams,
      flight: {
        ...searchParams.flight,
        allowOpenJaw: true,
      },
      paging: { resultsTotal: RESULTS_TOTAL, entity: 'Journey' },
      composition: 'JourneyIdentifierPriceCategoryCabinType',
      detailLevel: ['FlightSegments', 'PriceDetails'] as detailLevelEnum[],
    };
  }, [searchParams]);

  useAsyncEffect(
    async (onUnmount) => {
      try {
        setCruises(null);
        setFilteredCruises(null);
        setDisableTariffFilter(true);
        if (request instanceof Error) {
          throw request;
        }

        const cruiseList = convertCruises(
          await postCruiseList(request, withAbort(onUnmount)),
          baseData,
        );

        void (async () => {
          if (searchParams && cruiseList) {
            const filtered = cruiseList.filter((cruise: Cruise): boolean =>
              filteredTariffs.length === 0 || cruise.cabins.some((cabin) =>
                isFilteredOption(cabin.tariffCode, filteredTariffs)));
            trackGA4SearchEvent(filtered.length, searchParams, filteredTariffs.map((tariff) => tariff.code));
          }
        })();

        setCruises(cruiseList);
        setFilteredCruises(cruiseList);
        setDisableTariffFilter(false);
      } catch (err) {
        /* not really testable due to lacking visible impact for the user */
        /* istanbul ignore if */
        if (isAbort(err)) {
          return;
        }

        captureException(err, Severity.Warning);
        setCruises(SOME_ERROR);
      }
    },
    [request, history],
  );
  React.useEffect(() => cruises && Array.isArray(cruises) ?
      setFilteredCruises(cruises.filter((cruise: Cruise): boolean =>
        filteredTariffs.length === 0 || cruise.cabins.some((cabin) =>
          isFilteredOption(cabin.tariffCode, filteredTariffs)))) : undefined,
    [cruises, filteredTariffs],
  );

  const [trackTariff, setTrackTariff] = React.useState(false);

  return (
    <OuterContentWrap>
      <ContentWrap>
        <ContentConfigWrap>
          <PriceSwitch />
          <SelectInputWrap
            label="Tarife filtern"
            newStyle={true}
            disabled={disableTariffFilter}
            options={tariffs}
            onClose={() => {
              if (trackTariff) {
                trackGA4FormInteraction('kreuzfahrtsuche', 'filled.tariff-filter.' + filteredTariffs.map(tariff => tariff.code).join('|'));
                setTrackTariff(false);
              }
            }}
            onEnter={() => {
              trackGA4FormInteraction('kreuzfahrtsuche', 'click.tariff-filter');
            }}
            onChange={(event: Option) => {
              setTrackTariff(true);
              if (event.code === ALL_CODE) {
                // reset filtered tariffs
                setFilteredTariffs([]);
              } else {
                const filterIndex = filteredTariffs.findIndex(
                  (tariff: Option) => tariff.code === event.code,
                );
                if (filterIndex < 0) {
                  // add to filtered tariffs
                  if (event) {
                    setFilteredTariffs([
                      ...filteredTariffs,
                      event,
                    ]);
                  }
                } else {
                  // delete from filtered tariffs
                  setFilteredTariffs(filteredTariffs.filter((filteredTariff) =>
                    filteredTariff.code !== event.code));
                }
              }
            }}
            value={filteredTariffs}
          />
        </ContentConfigWrap>
        {cruises === SOME_ERROR ? (
          <>
            <ErrorWithReset />
            <br />
            <br />
          </>
        ) : (
          <CruiseList
            cruises={filteredCruises}
            filteredTariffs={filteredTariffs}
          />
        )}
      </ContentWrap>
    </OuterContentWrap>
  );
};

export default CruiseListProvider;
