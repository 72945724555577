import { isBefore, isEqual, parseISO } from 'date-fns';
import {
  cabinTypeEnum,
  CruiseListResponse,
  cruiseService,
  cruiseVariantCabin,
  cruiseVariants,
  flightService,
  priceDetails,
  priceInformationPassengers,
  serviceInfoResponse,
  ServiceInfoResponse,
  serviceResponse,
} from '../../api';
import { BaseData, captureException, IGNORE_ERROR, MISSING_FLIGHT_INFO, SentryData, withSentryData } from '../../lib';

import { Option } from '../../types';

import { BaseCabin, Cabin, CabinAndFlightPrice, Cruise, Price } from './Types';
import { ensureOptionArray } from '../../types/Option';
// import { ta } from 'date-fns/locale';

type ServiceContainerWithCruiseService = Required<
  Pick<serviceResponse[0], 'cruiseService' | 'id'>
>;
type TripByJourneyIdentifier = {
  [key: string]: serviceInfoResponse['trips'][0];
};
type OptionMap = {
  [key: string]: string;
};
type VariantWithCabin = Omit<cruiseVariants[0], 'cabin'> & {
  cabin: cruiseVariantCabin;
  priceDetails?: priceDetails
};
type CruiseAndFlightServices = {
  flightServices?: Array<flightService>;
  cruiseService: cruiseService & {
    variant: VariantWithCabin;
  };
};
type ProductWithResolvedService = CruiseListResponse['products'][0] &
  CruiseAndFlightServices;
type CruiseProduct = Omit<ProductWithResolvedService, 'flightServices'>;
type CruiseAndFlightProduct = CruiseProduct & {
  flightServices: Array<flightService>;
};
type Product = CruiseProduct | CruiseAndFlightProduct;
type ProductGroup = {
  cruiseProduct?: CruiseProduct;
  withFlights?: CruiseAndFlightProduct[];
};
type ProductsByJourneyIdentifier = {
  [key: string]: ProductGroup[];
};

function byDate({ startDate: A }: Cruise, { startDate: B }: Cruise) {
  if (isEqual(A, B)) {
    return 0;
  }

  if (isBefore(A, B)) {
    return -1;
  }

  return 1;
}

function isCruiseService(
  service: serviceResponse[0],
): service is ServiceContainerWithCruiseService {
  return typeof (service as any).cruiseService !== 'undefined';
}

function ensureCabin(variant: cruiseVariants[0]): VariantWithCabin {
  if (!variant.cabin) {
    throw new Error('Unexpected Variant without cabin infos');
  }

  return variant as VariantWithCabin;
}

function resolveProductServices(services: serviceResponse) {
  return (
    product: CruiseListResponse['products'][0],
  ): ProductWithResolvedService => ({
    ...product,
    ...(product.serviceReferences ?? []).reduce(
      (memo, { serviceId, serviceVariantId }) => {
        try {
          const service = services.find(({ id }) => id === serviceId);

          if (!service) {
            throw new Error(`Could not find service with id ${serviceId}`);
          }

          if (isCruiseService(service)) {
            if (serviceVariantId === undefined) {
              throw new Error(
                `Could not resolve variant of cruiseService with id ${serviceId}`,
              );
            }

            const { cruiseVariants } = service.cruiseService;
            const variant =
              cruiseVariants &&
              cruiseVariants.find(({ id }) => id === serviceVariantId);

            if (!variant) {
              throw new Error(
                `Could not find variant with id ${serviceVariantId} within service with id ${serviceId}`,
              );
            }

            if (memo.cruiseService) {
              throw new Error(
                `Unexpected multiple cruise services on service with id ${serviceId}`,
              );
            }

            memo.cruiseService = {
              ...service.cruiseService,
              variant: ensureCabin(variant),
            };
          } else {
            if (!service.flightService) {
              throw new Error(
                `Unexpected service type on service with id ${serviceId}`,
              );
            }

            if (!memo.flightServices) {
              memo.flightServices = [];
            }

            memo.flightServices.push(service.flightService);
          }

          return memo;
        } catch (err) {
          captureException(err);
          return memo;
        }
      },
      {} as CruiseAndFlightServices,
    ),
  });
}

function isCruiseAndFlightProduct(
  product: ProductWithResolvedService,
): product is CruiseAndFlightProduct {
  return !!product.flightServices;
}

function mergeCruiseAndFlightByJourneyIdentifier(
  memo: ProductsByJourneyIdentifier,
  product: ProductWithResolvedService,
) {
  const { journeyIdentifier } = product.cruiseService;

  if (!memo[journeyIdentifier]) {
    memo[journeyIdentifier] = [];
  }

  const { variant } = product.cruiseService;

  const existing = memo[journeyIdentifier].find((productGroup) => {
    const otherProduct =
      productGroup.cruiseProduct ||
      (productGroup.withFlights && productGroup.withFlights[0]);

    return otherProduct && otherProduct.cruiseService.variant === variant;
  });

  const productGroup: ProductGroup = existing || ({} as ProductGroup);

  if (isCruiseAndFlightProduct(product)) {
    if (!productGroup.withFlights) {
      productGroup.withFlights = [];
    }
    productGroup.withFlights.push(product);
  } else {
    productGroup.cruiseProduct = product;
  }

  if (!existing) {
    memo[journeyIdentifier].push(productGroup);
  }

  return memo;
}

function toPrice() {
  return ({ amount, title, id }: priceInformationPassengers[0]): Price => {
    if (!title) {
      throw new Error(`Missing title in Price with id ${id}`);
    }

    return {
      amount: amount ?? 0,
      title,
    };
  };
}

function resolveFlightServices(
  flightServices: flightService[],
  journeyIdentifier: String,
) {
  const outbound = flightServices.find(
    ({ direction }) => direction === 'Outbound',
  );
  const inbound = flightServices.find(
    ({ direction }) => direction === 'Inbound',
  );

  if ((!outbound || !outbound.productCode) && (!inbound || !inbound.productCode)) {
    throw withSentryData({ extras: { inbound, outbound } })(
      new Error(
        `Incomplete flight information on product with journeyIdentifier "${journeyIdentifier}"`,
      ),
    );
  }

  return { outbound, inbound };
}

function createCabinAndFlightPriceMapper(
  getAirportName: (code: string) => string,
) {
  return ({
            // id,
            flightServices,
            cruiseService: { journeyIdentifier },
            priceInformation,
          }: CruiseAndFlightProduct): CabinAndFlightPrice => {
    const { outbound, inbound } = resolveFlightServices(
      flightServices,
      journeyIdentifier,
    );

    const departureAirport = (outbound && {
      name: getAirportName(outbound.departureAirport),
      code: outbound.departureAirport!,
    }) || {};

    const arrivalAirport = (inbound && {
      name: getAirportName(inbound.arrivalAirport),
      code: inbound.arrivalAirport!,
    }) || {};

    return {
      amount: priceInformation?.amount ?? 0,
      perPerson: (priceInformation?.passengerPrices ?? []).map(toPrice()),
      departureAirport,
      arrivalAirport,
    };
  };
}

function createCodeToNameResolver(
  infoArray: Option[],
  errorMeta: SentryData = {},
) {
  const nameByCode = infoArray.reduce(
    (memo, cabin) => ({
      ...memo,
      [cabin.code]: cabin.name,
    }),
    {} as OptionMap,
  );

  return (code: string) => {

    if (!nameByCode[code]) {
      throw withSentryData(errorMeta)(
        new Error(`Could not get name for code ${code}`),
      );
    }

    return nameByCode[code];
  };
}

function onlyCheapest(products: CruiseAndFlightProduct[]) {
  return Object.values(
    products.reduce(
      (memo, product) => {
        const {
          priceInformation,
          flightServices,
          cruiseService: { journeyIdentifier },
        } = product;
        const { outbound, inbound } = resolveFlightServices(
          flightServices,
          journeyIdentifier,
        );
        const key = `${(outbound && outbound.departureAirport) || MISSING_FLIGHT_INFO}:${(inbound && inbound.arrivalAirport) || MISSING_FLIGHT_INFO}`;
        if (!memo[key] || memo[key].amount > (priceInformation?.amount ?? 0)) {
          memo[key] = {
            product,
            amount: priceInformation?.amount ?? 0,
          };
        }

        return memo;
      },
      {} as {
        [key: string]: { amount: number; product: CruiseAndFlightProduct };
      },
    ),
  ).map(({ product }) => product);
}

function toCruise(
  { trips }: ServiceInfoResponse,
  baseData: BaseData,
) {
  const tripByJourneyIdentifier: TripByJourneyIdentifier = trips.reduce(
    (memo, trip) => ({
      ...memo,
      [trip.journeyIdentifier]: trip,
    }),
    {} as TripByJourneyIdentifier,
  );
  const getCabinName = createCodeToNameResolver(ensureOptionArray(baseData.cruise?.cabinTypeInfos));
  const getTariffName = createCodeToNameResolver(ensureOptionArray(baseData.cruise?.tariffs), {
    [IGNORE_ERROR]: true,
  });
  const getShipName = createCodeToNameResolver(ensureOptionArray(baseData.cruise?.ships));

  const toCabinAndFlightPrice = createCabinAndFlightPriceMapper(
    createCodeToNameResolver(ensureOptionArray(ensureOptionArray(baseData.flight?.airports))),
  );

  return ([journeyIdentifier, productGroups]: [
    string,
    ProductGroup[],
  ]): Cruise | null => {
    try {
      const {
        cruiseService: { startDate, shipCode },
      } = productGroups[0].cruiseProduct || productGroups[0].withFlights![0];
      const trip = tripByJourneyIdentifier[journeyIdentifier];

      if (!trip) {
        throw new Error(
          `Could not find trip for journey with identifier ${journeyIdentifier}`,
        );
      }

      const cabins = productGroups
        .map(({ cruiseProduct, withFlights }): Cabin | null => {
          if (!withFlights && !cruiseProduct) {
            /* This should never happen */
            throw new Error(
              `Unexpectedly got no products for journey with identifier ${journeyIdentifier}`,
            );
          }

          const cabinProducts = ([] as Product[])
            .concat(cruiseProduct || [])
            .concat(withFlights || []);

          const {
            cabinType,
            cabinCode,
            tariffType,
          } = {
            ...cabinProducts[0].cruiseService.variant.cabin,
            cabinType: ((cabinProducts[0].cruiseService.variant.cabin.cabinType ?? 'null') as string).replace('Single', '') as cabinTypeEnum,
            tariffType: cabinProducts[0].cruiseService.variant.cabin.tariffType ?? '',
          };

          const { packageReference } = cabinProducts[0].cruiseService;

          if (!packageReference) {
            throw new Error(
              `Missing \`services[].cruiseService.packageReference\` for journeyIdentifier "${journeyIdentifier}"`,
            );
          }

          const earlyBird = cabinProducts.some(
            ({ priceInformation }) =>
              priceInformation?.priceDetails?.some(({ priceType }) => priceType === 'EarlyBird'),
          );

          const singleExtra = cabinProducts[0].priceInformation?.priceDetails?.find((detail) => detail.codes?.includes('singleExtra'));

          let baseCabin: BaseCabin;

          try {
            baseCabin = {
              name: getCabinName(cabinType),
              type: cabinType,
              code: cabinCode,
              tariff: getTariffName(tariffType),
              earlyBird,
              tariffCode: tariffType,
              packageReference,
              singleExtra,
            };
          } catch (err) {
            captureException(err);
            return null;
          }

          if (cruiseProduct && !withFlights) {
            return {
              ...baseCabin,
              price: {
                amount: cruiseProduct.priceInformation?.amount ?? 0,
                perPerson: (cruiseProduct.priceInformation?.passengerPrices ?? []).map(
                  toPrice(),
                ),
              },
            };
          }

          if (withFlights && !cruiseProduct) {
            return {
              ...baseCabin,
              pricesWithFlight: onlyCheapest(withFlights).map(
                toCabinAndFlightPrice,
              ),
            };
          }

          if (!withFlights || !cruiseProduct) {
            /* This should never happen */
            throw new Error(
              `Unexpectedly got no products for journey with identifier ${journeyIdentifier}`,
            );
          }

          return {
            ...baseCabin,
            price: {
              amount: cruiseProduct.priceInformation?.amount ?? 0,
              perPerson: (cruiseProduct.priceInformation?.passengerPrices ?? []).map(
                toPrice(),
              ),
            },
            pricesWithFlight: onlyCheapest(withFlights).map(
              toCabinAndFlightPrice,
            ),
          };
        })
        .filter((cabin: Cabin | null): cabin is Cabin => Boolean(cabin));

      return {
        ...trip,
        journeyIdentifier,
        startDate: parseISO(startDate),
        shipName: getShipName(shipCode),
        shipCode,
        cabins,
        region: '',
      };
    } catch (err) {
      captureException(err);
      return null;
    }
  };
}

function responseToCruiseList(
  { products, services, serviceInfos }: CruiseListResponse,
  data: BaseData,
): Cruise[] {
  if (products.length === 0) {
    return [];
  }
  if (!serviceInfos) {
    return [];
  }

  return Object.entries(
    products
      .map(resolveProductServices(services))
      .reduce(mergeCruiseAndFlightByJourneyIdentifier, {}),
  )
    .map(toCruise(serviceInfos, data))
    .filter((cruise: Cruise | null): cruise is Cruise => Boolean(cruise))
    .filter(({ cabins }) => cabins.length);
}

export default function responsesToCruiseList(
  cruiseListResponses: CruiseListResponse | CruiseListResponse[],
  baseData: BaseData,
): Cruise[] {
  return ([] as CruiseListResponse[])
    .concat(cruiseListResponses)
    .reduce(
      (memo, cruiseListResponse): Cruise[] => {
        return memo.concat(responseToCruiseList(cruiseListResponse, baseData));
      },
      [] as Cruise[],
    )
    .sort(byDate);
}
