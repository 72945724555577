import { Option } from '../types/Option';

interface Airport {
  iata: string;
  name: string;
}

interface ResponseAirport {
  [key: string]: Airport;
}


export const getAirports = async (): Promise<Option[]> => {
  return fetch('https://api.tuicruises.com/pcl/airports', {
    method: 'GET',
  })
    .then((response) => response.json())
    .then((data) => {
      if (data) {
        const airportsTemp = data as ResponseAirport;
        return Object.keys(airportsTemp).map((key) => ({
          code: key,
          name: airportsTemp[key].name,
        }));
      }
      return [];
    });
};
