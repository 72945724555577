import * as React from 'react';
import styled from 'styled-components/macro';
import Downshift from 'downshift';

import {
  ALL_CODE,
  ALL_LABEL,
  ArrowDown,
  ArrowUp,
  CheckboxSmallTicked,
  CheckboxSmallUnTicked,
  colors,
  SELECTION_LABEL,
} from '../lib';
import { Option } from '../types';

import Input from './Input';
import InputLabel, { LABEL_VERTICAL_PADDING, LABEL_WIDTH } from './InputLabel';

type InputProps = {
  options: Option[];
  disabled?: boolean;
  value: Option;
  active: boolean;
  setActive: (active: boolean) => void;
  onChange: (value: Option) => void;
  newStyle?: boolean;
  tabIndex?: number;
  onClose?: () => void;
};

type MultiSelectProps = Omit<InputProps, 'value'> & {
  value: Option[];
  tabIndex?: number;
};

type SingleWrapperProps = Omit<InputProps, 'active' | 'setActive'> & {
  label: string;
  tabIndex?: number;
  onEnter?: () => void;
};
type MultiWrapperProps = Omit<MultiSelectProps, 'active' | 'setActive'> & {
  label: string;
  newStyle?: boolean;
  tabIndex?: number;
  onEnter?: () => void;
};

type Props = {
  className?: string;
  ticked?: boolean;
};

const SmallCheckbox: React.FC<Props> = ({ className, ticked }) => {
  const Element = ticked ? CheckboxSmallTicked : CheckboxSmallUnTicked;

  return <Element className={className} tabIndex={-1} focusable="false" />;
};

const List = styled.ul`
  width: ${LABEL_WIDTH + LABEL_VERTICAL_PADDING * 2}px;
  max-height: ${({ newStyle }: { newStyle?: boolean }) =>
    newStyle ? '224px' : '220px'
  };
  overflow-y: ${({ newStyle }: { newStyle?: boolean }) =>
    newStyle ? 'auto' : 'scroll'
  };
  margin: 16px 0 0 -${LABEL_VERTICAL_PADDING}px;
  list-style: none;
  padding: 0;
  outline: none;
`;

type ListItemProps = {
  noBold?: boolean;
  addPadding?: boolean;
  separate?: boolean;
  active?: boolean;
  selected?: boolean;
};

const CHECKBOX_WIDTH = 20;
const CHECKBOX_MARGIN = 8;
const StyledCheckbox = styled(SmallCheckbox)`
  width: ${CHECKBOX_WIDTH}px;
  height: ${CHECKBOX_WIDTH}px;
  margin: 0 ${CHECKBOX_MARGIN}px ${CHECKBOX_MARGIN}px -${CHECKBOX_MARGIN + CHECKBOX_WIDTH}px;
  float: left;
`;

const LIST_HIGHLIGHT_COLOR = 'rgba(200, 206, 217, 0.3)';
const ListItem = styled.li`
  font-size: 18px;
  line-height: 20px;
  padding: 8px ${LABEL_VERTICAL_PADDING}px 8px ${({ addPadding }: ListItemProps) =>
    addPadding
      ? LABEL_VERTICAL_PADDING + CHECKBOX_WIDTH + CHECKBOX_MARGIN
      : LABEL_VERTICAL_PADDING}px;
  min-height: 36px;
  color: ${colors.blue};
  cursor: ${({ selected }: ListItemProps) =>
    selected ? 'default' : 'pointer'};
  font-weight: ${({ selected, noBold }: ListItemProps) =>
    selected && !noBold ? 'bold' : 'normal'};
  background: ${({ active }: ListItemProps) =>
    active ? LIST_HIGHLIGHT_COLOR : 'transparent'};
  margin-bottom: ${({ separate }: ListItemProps) => (separate ? '8px' : 0)};
`;

type IconsWrapProps = {
  disabled?: boolean;
};

const IconsWrap = styled.span`
  display: block;
  width: 100%;
  position: relative;
  height: 0;

  svg {
    color: ${({ disabled }: IconsWrapProps) =>
      disabled ? colors.disabledGray : colors.darkBlue};
    right: 0;
    top: 10px !important;
    user-select: none;
    cursor: pointer;
    position: absolute;
  }
`;

type IconsProps = {
  disabled?: boolean;
  setActive: (active: boolean) => void;
  active: boolean;
};

const StyledArrowDown = styled(ArrowDown)`
  pointer-events: none;
`;

const SelectIcons: React.FC<IconsProps> = ({ setActive, active, disabled }) => {
  const close = React.useCallback(
    (ev: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
      ev.stopPropagation();
      ev.preventDefault();
      setActive(false);
    },
    [setActive],
  );

  return (
    <IconsWrap disabled={disabled}>
      {active ? (
        <ArrowUp data-testid="up-arrow" onClick={close}
                 tabIndex={-1}
                 focusable="false" />
      ) : (
        <StyledArrowDown
          tabIndex={-1}
          focusable="false" />
      )}
    </IconsWrap>
  );
};

/* item should technically never be null
   but downshift seems to play against the rules sometimes */
function itemToString(item: Option | null) {
  return item === null ? '' : item.name;
}

const ButtonText = styled.span`
  width: ${LABEL_WIDTH - 18}px;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis;
`;

const ALL_ENTRY: Option = {
  separate: true,
  code: ALL_CODE,
  name: ALL_LABEL,
};

function isSelected(
  value: Option | Option[],
  item: Option,
  allSelected: boolean,
) {
  if (item.code === ALL_ENTRY.code && allSelected) {
    return true;
  }

  if (allSelected) {
    return false;
  }

  return Array.isArray(value) ? value.some((v) => v.code === item.code) : value.code === item.code;
}

const MultiListItem: React.FC<ListItemProps> = ({
                                                  children,
                                                  selected,
                                                  ...rest
                                                }) => {
  return (
    <ListItem selected={selected} {...rest} noBold addPadding>
      <StyledCheckbox ticked={selected} /> {children}
    </ListItem>
  );
};

const ButtonInput = styled(Input)`
  cursor: ${({ disabled }: { disabled?: boolean, newStyle?: boolean }) =>
    disabled ? 'default' : 'pointer'};
`;

const SelectInput: React.FC<InputProps | MultiSelectProps> = ({
                                                                onChange,
                                                                options,
                                                                value,
                                                                active,
                                                                setActive,
                                                                disabled,
                                                                newStyle,
                                                                tabIndex,
                                                              }) => {
  const isMultiSelect = Array.isArray(value);
  const allSelected = Array.isArray(value) && value.length === 0;
  const selectAndClose = React.useCallback(
    (value: Option) => {
      if (!isMultiSelect) {
        setActive(false);
      }
      onChange(value);
    },
    [setActive, onChange, isMultiSelect],
  );
  const getMultiValue = React.useCallback(
    (value: Option[]) => {
      if (allSelected) {
        return newStyle ? SELECTION_LABEL : ALL_LABEL;
      }

      return <ButtonText>{value.map(itemToString).join(', ')}</ButtonText>;
    },
    [allSelected, newStyle],
  );

  if (disabled) {
    return (
      <ButtonInput
        disabled={true}
        newStyle={newStyle}
        as="button"
        children={newStyle ? SELECTION_LABEL :
          Array.isArray(value) ? getMultiValue(value) : itemToString(value)
        }
      />
    );
  }

  return (
    <Downshift
      itemToString={itemToString}
      onChange={selectAndClose}
      initialSelectedItem={value}
      selectedItem={isMultiSelect ? null : undefined}
    >
      {({ getInputProps, getMenuProps, getItemProps, highlightedIndex }) => {
        return (
          <div>
            <ButtonInput
              as="button"
              tabIndex={tabIndex}
              {...getInputProps()}
              newStyle={newStyle}
              children={newStyle ? SELECTION_LABEL :
                Array.isArray(value)
                  ? getMultiValue(value)
                  : itemToString(value)
              }
            />
            {active && (
              <List
                tabIndex={-1}
                newStyle={newStyle}
                {...getMenuProps({
                  open: active,
                })}
              >
                {(isMultiSelect ? [ALL_ENTRY] : [])
                  .concat(options)
                  .map((item, index) => {
                    const Element = isMultiSelect ? MultiListItem : ListItem;

                    return (
                      <Element
                        key={item.code}
                        active={highlightedIndex === index}
                        selected={isSelected(value, item, allSelected)}
                        separate={item.separate}
                        {...getItemProps({
                          item,
                        })}
                      >
                        {item.name}
                      </Element>
                    );
                  })}
              </List>
            )}
          </div>
        );
      }}
    </Downshift>
  );
};

const SelectInputWrap: React.FC<SingleWrapperProps | MultiWrapperProps> = ({
                                                                             label,
                                                                             disabled,
                                                                             onClose,
                                                                             ...rest
                                                                           }) => {
  const { newStyle } = { newStyle: false, ...rest };

  return (
    <InputLabel
      disabled={disabled}
      label={label}
      newStyle={newStyle}
      onClose={onClose}
      onEnter={rest.onEnter}
      render={(active, setActive) => (
        <>
          <SelectIcons
            disabled={disabled}
            active={active}
            setActive={setActive}
          />
          <SelectInput
            disabled={disabled}
            active={active}
            setActive={setActive}
            {...rest}
          />
        </>
      )}
    />
  );
};

export default SelectInputWrap;
