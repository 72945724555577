export type Option = {
  separate?: boolean;
  code: string;
  name: string;
};

export function isOption(data: any): data is Option {
  return (
    data &&
    typeof data === 'object' &&
    typeof data.code === 'string' &&
    typeof data.name === 'string'
  );
}

export function isFilteredOption(code: string, filteredOptions: Option[] | [] = []) {
  return filteredOptions.length === 0 || filteredOptions.some(
    (option) => option.code === code,
  );
}


export function ensureOptionArray(options: { code?: string, name?: string }[] | undefined): Option[] {
  return (options ?? []).filter((option) => !!option.code).map((option) => {
    return {
      code: option.code ?? '',
      name: option.name ?? '',
    };
  });
}
