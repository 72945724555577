import * as React from 'react';
import styled from 'styled-components/macro';

import { usePriceFormat } from '../PriceSwitch';
import { Title } from '../Table';

import { Cabin, isCabinWithFlight, isCabinWithFlightOnly } from './Types';
import byAmount from './sortByAmount';
import { colors } from '../../lib';


type PriceProps = {
  bold?: boolean;
};
const Price = styled(Title)`
  font-weight: ${({ bold }: PriceProps) => (bold ? 'bold' : 'normal')};
  height: 28px;
  line-height: 28px;
`;

type PriceWithFlightProps = {
  children: string;
};

function fontSizeByContentLength(length: number) {
  return 16 - Math.max(length - 23, 0);
}

const PriceWithFlight = styled.span`
  display: block;
  height: 22px;
  white-space: nowrap;
  line-height: 22px;
  font-size: ${({ children }: PriceWithFlightProps) =>
    fontSizeByContentLength(children.length)}px;
`;

type Props = PriceProps & {
  cabin: Cabin;
  fixedHeight?: boolean;
  showEz?: boolean;
};

const CabinTitle = styled.span`
  display: block;
  font-size: 16px;
  color: ${({ filtered }: { filtered?: boolean }) =>
    filtered || filtered === undefined ? colors.grayBlue : colors.disabledGray
  };
  height: 22px;
  line-height: 22px;
`;

const CabinPrice: React.FC<Props> = React.memo(
  ({ bold, cabin, fixedHeight, showEz }) => {
    const formatPrice = usePriceFormat();

    if (isCabinWithFlight(cabin)) {
      const price = formatPrice(cabin.pricesWithFlight.sort(byAmount)[0]);
      return (
        <>
          <Price bold={bold}>ab {formatPrice(cabin.price)}</Price>
          <PriceWithFlight>{`inkl. Flug ab ${price}`}</PriceWithFlight>
          {(showEz && cabin.singleExtra) && <CabinTitle>
            {`${cabin.singleExtra.percent}% **`}
          </CabinTitle>}
        </>
      );
    }

    if (isCabinWithFlightOnly(cabin)) {
      return (
        <>
          <Price bold={bold}>
            ab {formatPrice(cabin.pricesWithFlight.sort(byAmount)[0])}
          </Price>
          <PriceWithFlight>inkl. Flug</PriceWithFlight>
          {(showEz && cabin.singleExtra) && <CabinTitle>
            {`${cabin.singleExtra.percent}% **`}
          </CabinTitle>}
        </>
      );
    }

    return (
      <>
        <Price bold={bold}>ab {formatPrice(cabin.price)}</Price>
        {(fixedHeight && (!showEz && !cabin.singleExtra)) ? <PriceWithFlight children="" /> : null}
        {(showEz && cabin.singleExtra) && <CabinTitle>
          {`${cabin.singleExtra.percent}% **`}
        </CabinTitle>}
      </>
    );
  },
);

export default CabinPrice;
