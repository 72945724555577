import * as React from 'react';
import styled from 'styled-components/macro';

import ContentWrap, { OuterContentWrap } from './ContentWrap';

const OuterWrap = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 4;
`;

const Wrapper = styled(OuterContentWrap)`
  position: absolute;
`;

export function useHorizontalScroll<E extends HTMLElement>() {
  const ref = React.useRef<E | null>(null);
  React.useEffect(() => {
    const listener = () => {
      window.requestAnimationFrame(() => {
        if (!ref.current) {
          return;
        }

        ref.current.style.left = `-${window.scrollX}px`;
      });
    };
    listener();
    window.addEventListener('scroll', listener);

    return () => {
      window.removeEventListener('scroll', listener);
    };
  }, []);

  return ref;
}

const Fixed: React.FC<{ className?: string }> = ({ children, className }) => {
  const ref = useHorizontalScroll<HTMLDivElement>();
  return (
    <OuterWrap className={className}>
      <Wrapper ref={ref}>
        <ContentWrap>{children}</ContentWrap>
      </Wrapper>
    </OuterWrap>
  );
};

export default Fixed;
