import { cabinTypeEnum, priceDetails_inner, serviceTrips } from '../../api';

export type Price = {
  amount: number;
  title: string;
};
export type Flight = {
  from: string;
  to: string;
  prices: Price[];
};


export type SingleExtra = priceDetails_inner & {percent?: number};

export type BaseCabin = {
  name: string;
  type: cabinTypeEnum;
  code: string;
  tariff: string;
  earlyBird: boolean;
  tariffCode: string;
  packageReference: string;
  singleExtra?: SingleExtra
};

export type CabinPrice = {
  amount: number;
  perPerson: Price[];
};
export type CabinAndFlightPrice = CabinPrice & {
  departureAirport: {
    name?: string;
    code?: string;
  };
  arrivalAirport: {
    name?: string;
    code?: string;
  };
};
export type CabinWithoutFlight = BaseCabin & {
  price: CabinPrice;
};

type CabinWithFlightOnly = BaseCabin & {
  pricesWithFlight: CabinAndFlightPrice[];
};
type CabinWithFlight = BaseCabin & {
  price: CabinPrice;
  pricesWithFlight: CabinAndFlightPrice[];
};

export type Cabin = CabinWithoutFlight | CabinWithFlightOnly | CabinWithFlight;

export function isCabinWithoutFlight(
  cabin: Cabin,
): cabin is CabinWithoutFlight {
  return !(cabin as any).pricesWithFlight;
}

export function isCabinWithFlightOnly(
  cabin: Cabin,
): cabin is CabinWithFlightOnly {
  return !(cabin as any).price;
}

export function isCabinWithoutInboundFlight(cabinAndFlightPrice: CabinAndFlightPrice) {
  return !cabinAndFlightPrice.arrivalAirport.code;
}

export function isCabinWithoutOutboundFlight(cabinAndFlightPrice: CabinAndFlightPrice) {
  return !cabinAndFlightPrice.departureAirport.code;
}

export function isCabinWithFlight(cabin: Cabin): cabin is CabinWithFlight {
  return Boolean((cabin as any).price && (cabin as any).pricesWithFlight);
}

export type Cruise = serviceTrips[0] & {
  startDate: Date;
  shipName: string;
  cabins: Cabin[];
  shipCode?: string;
  region: string
};
